// Inspired by Bootstrap's alert
.alert-info {
  background-color: #d9edf7;
  border: 1px solid;
  border-color: #bce8f1;
  padding: 1em;
  border-radius: 4px;

  p {
    margin: 0;
  }
}
